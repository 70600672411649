import styled from "styled-components"
import media from "styled-media-query"
import Img from "gatsby-image"

export const AvatarWrapper = styled(Img)`
  border-radius: 50%;
  height: 8rem;
  margin: auto;
  width: 8rem;

  ${media.lessThan("large")`
    height: 2rem;
    width: 2rem;
  `}

  ${media.lessThan("510px")`
    height: 8.5%;
    width: 8.5%;
  `}

  ${media.lessThan("475px")`
    min-width: 38px;
  `}
`
