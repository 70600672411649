import { Github } from "@styled-icons/boxicons-logos/Github"
import { Kaggle } from "@styled-icons/fa-brands/Kaggle"
import { Linkedin } from "@styled-icons/boxicons-logos/Linkedin"

const Icons = {
  Github,
  Kaggle,
  Linkedin
}

export default Icons
