const links = [
  {
    label: "Linkedin",
    url: `https://www.linkedin.com/in/igor-campos-bb4160113`,
  },
  {
    label: "Github",
    url: `https://github.com/igorbalteiro`,
  },
  {
    label: "Kaggle",
    url: `https://www.kaggle.com/igorbalteiro`,
  }
]

export default links
